import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "quantityInput",
    "multiplier",
    "warningAlert"
  ]

  static values = { lotSize: Number }

  connect() { this.checkQuantity() }

  checkQuantity() {
    const quantity = parseInt(this.quantityInputTarget.value)
    if (isNaN(quantity)) { return }

    if (quantity % this.lotSizeValue === 0) {
      this.hideWarning()
    } else {
      this.showWarning()
    }
  }

  hideWarning() {
    if (!this.hasWarningAlertTarget) { return }
    this.warningAlertTarget.classList.add("d-none")
  }

  showWarning() {
    if (!this.hasWarningAlertTarget) { return }
    this.warningAlertTarget.classList.remove("d-none")
  }
}

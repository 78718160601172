import { Controller } from 'stimulus'

function parseWeight(value, separator) {
  return parseFloat(value.replace(separator, '.'))
}

function formatWeight(value, separator) {
  return value.toString().replace('.', separator)
}

export default class extends Controller {
  static targets = ['target', 'template', 'totalQuantity', 'totalPieces', 'totalVolume', 'totalWeight', 'totalPiecesWarning']
  static values = {
    originalPieces: Number,
    defaultSizes: Object,
    productSizes: Object,
    separator: String,
  }

  connect() {
    for(let el of this.element.querySelectorAll('.shipping-item-input')) {
      el.addEventListener('change', this.handleChanged.bind(this))
      el.addEventListener('keydown', this.handleKeyDown.bind(this))
    }
  }

  add(e) {
    e.preventDefault()

    const sourceItem = this.lastItem

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    if(sourceItem) this.prefillNewItem(sourceItem)

    for(let el of this.lastItem.querySelectorAll('.shipping-item-input')) {
      el.addEventListener('change', this.handleChanged.bind(this))
      el.addEventListener('keydown', this.handleKeyDown.bind(this))
    }
  }

  remove(e) {
    e.preventDefault()

    const item = e.target.closest('.shipping-item-wrapper')

    if(item.dataset.newRecord === 'true') {
      item.remove()
    } else {
      item.classList.add('is--hidden')

      // Remove the element's min attribute so the form can be submitted
      const input = item.querySelector("input.shipping-item-input")
      input.removeAttribute('min')

      const hiddenDestroyField = item.querySelector("input[name*='_destroy']")
      hiddenDestroyField.value = '1'
    }

    this.handleChanged()
  }

  handleKeyDown(e) {
    if(e.keyCode === 13) {
      e.preventDefault()
      this.element.requestSubmit()
    }
  }

  handleChanged(e) {
    let totalQuantity = 0
    let totalPieces   = 0
    let totalVolume   = 0
    let totalWeight   = 0

    if(e && e.target.classList.contains('shipping-item-package-type')) {
      const packageType = e.target.value
      const item = e.target.closest('.shipping-item-wrapper')

      this.setDefaultDimensions(item, packageType)
    }

    if(e && e.target.classList.contains('shipping-item-inquiry-offer')) {
      const inquiryOfferId = e.target.value
      const item = e.target.closest('.shipping-item-wrapper')

      if(this.productSizesValue[inquiryOfferId]) {
        this.setDimensionsByProductChange(item, this.productSizesValue[inquiryOfferId])
      }
    }

    if(e && e.target.classList.contains('shipping-item-pieces')) {
      const item = e.target.closest('.shipping-item-wrapper')

      this.setDimensionsByPiecesChange(item)
    }

    if(e && e.target.classList.contains('shipping-item-package-type')) {
      const item = e.target.closest('.shipping-item-wrapper')

      this.setDimensionsByPackageTypeChange(item)
    }

    for(let item of this.items) {
      if(!item.classList.contains('is--hidden')) {
        const quantity = parseInt(item.querySelector('.shipping-item-quantity').value)
        const pieces   = parseInt(item.querySelector('.shipping-item-pieces').value)

        if(quantity) totalQuantity += quantity
        if(pieces && quantity) totalPieces += (pieces * quantity)

        const width  = parseInt(item.querySelector('.shipping-item-width').value)
        const height = parseInt(item.querySelector('.shipping-item-height').value)
        const length = parseInt(item.querySelector('.shipping-item-length').value)
        if(width && height && length && quantity) totalVolume += width * height * length * quantity

        const weight = parseWeight(item.querySelector('.shipping-item-weight').value, this.separatorValue)

        if(weight && quantity) totalWeight += weight * quantity
      }
    }

    this.totalQuantityTarget.innerHTML = totalQuantity || '-'
    this.totalPiecesTarget.innerHTML   = totalPieces || '-'
    this.totalVolumeTarget.innerHTML   = totalVolume ? (totalVolume / 1000000.0).toFixed(2) : '-'
    this.totalWeightTarget.innerHTML   = totalWeight ? formatWeight(Math.round(totalWeight), this.separatorValue) : '-'
  }

  prefillNewItem(sourceItem) {
    const targetItem = this.lastItem

    targetItem.querySelector('.shipping-item-quantity').value = sourceItem.querySelector('.shipping-item-quantity').value
    targetItem.querySelector('.shipping-item-pieces').value = sourceItem.querySelector('.shipping-item-pieces').value
    targetItem.querySelector('.shipping-item-width').value = sourceItem.querySelector('.shipping-item-width').value
    targetItem.querySelector('.shipping-item-height').value = sourceItem.querySelector('.shipping-item-height').value
    targetItem.querySelector('.shipping-item-length').value = sourceItem.querySelector('.shipping-item-length').value
    targetItem.querySelector('.shipping-item-weight').value = sourceItem.querySelector('.shipping-item-weight').value
    targetItem.querySelector('.shipping-item-package-type').value = sourceItem.querySelector('.shipping-item-package-type').value
    targetItem.querySelector('.shipping-item-stackable').checked = sourceItem.querySelector('.shipping-item-stackable').checked
    targetItem.querySelector('.shipping-item-inquiry-offer').value = sourceItem.querySelector('.shipping-item-inquiry-offer').value

    this.setDefaultDimensions(targetItem, targetItem.querySelector('.shipping-item-package-type').value)
  }

  setDefaultDimensions(item, packageType) {
    if(this.defaultSizesValue[packageType]) {
      item.querySelector('.shipping-item-width').value = this.defaultSizesValue[packageType].width / 10
      item.querySelector('.shipping-item-height').value = this.defaultSizesValue[packageType].height / 10
      item.querySelector('.shipping-item-length').value = this.defaultSizesValue[packageType].length / 10
      item.querySelector('.shipping-item-weight').value = formatWeight(this.defaultSizesValue[packageType].weight, this.separatorValue)
    }
  }

  setDimensionsByProductChange(item) {
    const productDimensions = this.getProductDimensions(item)
    const defaultDimensions = this.getDefaultDimensions(item)

    if(!productDimensions) return

    if(productDimensions.packaging_type) {
      item.querySelector('.shipping-item-package-type').value = productDimensions.packaging_type
    }

    if(productDimensions.box_width) {
      item.querySelector('.shipping-item-width').value = productDimensions.box_width / 10
    } else {
      if(defaultDimensions && defaultDimensions.width) {
        item.querySelector('.shipping-item-width').value = defaultDimensions.width / 10
      }
    }

    if(productDimensions.box_length) {
      item.querySelector('.shipping-item-length').value = productDimensions.box_length / 10
    } else {
      if(defaultDimensions && defaultDimensions.length) {
        item.querySelector('.shipping-item-length').value = defaultDimensions.length / 10
      }
    }

    if(productDimensions.box_height) {
      item.querySelector('.shipping-item-height').value = productDimensions.box_height / 10
    } else {
      if(defaultDimensions && defaultDimensions.height) {
        item.querySelector('.shipping-item-height').value = defaultDimensions.height / 10
      }
    }

    if(productDimensions.weight) {
      const pieces = this.getPieces(item)
      let weight = productDimensions.weight * pieces

      if(defaultDimensions && defaultDimensions.weight) {
        weight += defaultDimensions.weight
      }

      item.querySelector('.shipping-item-weight').value = formatWeight(weight, this.separatorValue)
    }
  }

  setDimensionsByPiecesChange(item) {
    const packageWeight = this.getDefaultPackageWeight(item)
    const productWeight = this.getProductWeight(item)
    const pieces = this.getPieces(item)

    item.querySelector('.shipping-item-weight').value = formatWeight(packageWeight + (productWeight * pieces), this.separatorValue)
  }

  setDimensionsByPackageTypeChange(item) {
    const [ prevPackageType ] = this.readPackageType(item)
    const productWeight = this.getProductWeight(item, prevPackageType)
    const pieces = this.getPieces(item)
    const packageWeight = this.getDefaultPackageWeight(item)

    item.querySelector('.shipping-item-weight').value = formatWeight(packageWeight + (productWeight * pieces), this.separatorValue)
  }

  get items() {
    return document.querySelectorAll('.shipping-item-wrapper')
  }

  get lastItem() {
    const items = this.items

    return items[items.length - 1]
  }

  getDefaultSizesForPackageType(packageType) {
    return this.defaultSizesValue[packageType]
  }

  getDefaultDimensions(item) {
    const input = item.querySelector('.shipping-item-package-type')

    return this.getDefaultSizesForPackageType(input.value)
  }

  getDefaultPackageWeight(item) {
    const dimensions = this.getDefaultDimensions(item)

    return dimensions && dimensions.weight ? dimensions.weight : 0
  }

  getProductDimensions(item) {
    const inquiryOfferId = item.querySelector('.shipping-item-inquiry-offer').value

    return this.productSizesValue[inquiryOfferId]
  }

  getProductWeight(item, prevPackageType = null) {
    const productDimensions = this.getProductDimensions(item)

    if(productDimensions && productDimensions.weight) {
      return productDimensions.weight
    }

    if(!prevPackageType) return 0

    const packageDimensions = this.getDefaultSizesForPackageType(prevPackageType)

    if(!packageDimensions || !packageDimensions.weight) return 0

    const packageWeight = packageDimensions.weight

    const input = item.querySelector('.shipping-item-weight')

    if(input.value === '') return 0

    const totalWeight = parseWeight(input.value, this.separatorValue)
    const pieces = this.getPieces(item)

    let netWeight = totalWeight - packageWeight

    return netWeight / pieces
  }

  getPieces(item) {
    const input = item.querySelector('.shipping-item-pieces')

    return parseInt(input.value) || 1
  }

  readPieces(item) {
    const input = item.querySelector('.shipping-item-pieces')

    const pieces = parseInt(input.value)
    const prevPieces = parseInt(input.dataset.prev)
    input.dataset.prev = pieces

    return [prevPieces, pieces]
  }

  readPackageType(item) {
    const input = item.querySelector('.shipping-item-package-type')

    const packageType = input.value
    const prevPackageType = input.dataset.prev
    input.dataset.prev = packageType

    return [prevPackageType, packageType]
  }
}
